import async from 'async-es'

export const executeActions = (actions = []) => {
  if (actions instanceof Array && actions.length) {
    // Esta función nos permite ejecutar acciones en paralelo o en serie segun un parametro order
    const key = 'order'
    const grouped = Object.values(actions.reduce((rv, x) => {
      (rv[x[key] || 0] = rv[x[key] || 0] || []).push(x)
      return rv
    }, {}))
    return async.series(grouped.map((group) => {
      return (serieResponse) => {
        async.parallel(group.map(e => (parallelResponse) => executeFunction(e.function, parallelResponse))).then(() => {
          serieResponse()
        })
      }
    }))
  }
}
const executeFunction = (exec, resolve) => {
  if (exec) {
    try {
      let fun = new Function(['ctx', 'resolve'], exec)
      return fun.apply(this, [this, resolve])
    } catch (e) {
      return exec
    }
  }
}
